import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { HrefButton } from "../UI/Buttons"
import Logo from "../../../static/assets/icons/logo.svg"
import VolunteerForm from "../UI/volunteer-form"

const GetInvolved = ({ involved, donate, venues }) => {
  const { body, donations, roles, subtitle, title, imagegrid } = involved

  const Roles = roles.map((role, i) => {
    const color = ["red", "orange", "yellow", "beige"]
    let end = ""
    if (i === roles.length - 1) end = "text-align-end"
    return (
      <div
        key={i}
        className="list-item"
        style={{ background: `var(--cafe-color-${color[i]})` }}
      >
        <h5 className={`${end}`}>{role}</h5>
      </div>
    )
  })
  return (
    <div
      className="section-grid"
      id="get-involved"
      style={{ paddingBottom: "50px", gridRowGap: "1em" }}
    >
      <div>
        <h1>{title}</h1>
        <br />
        <h3>{subtitle}</h3>
        <h4>{body}</h4>

        <div style={{ padding: "2rem 0" }}>{Roles}</div>
        <div>
          <h3>Volunteering form</h3>
          <VolunteerForm venues={venues} />
        </div>
      </div>

      <div>
        <div className="image-grid">
          <div className="grid-block-1">
            <Logo />
            <GatsbyImage
              image={imagegrid.image1.childImageSharp.gatsbyImageData}
              alt="volunteering"
            />
          </div>
          <GatsbyImage
            image={imagegrid.image2.childImageSharp.gatsbyImageData}
            alt="donations"
          />
          <div className="grid-block-span">
            <GatsbyImage
              image={imagegrid.image3.childImageSharp.gatsbyImageData}
              alt="food preparation"
            />
          </div>
          <p className="grid-block-span">{imagegrid.roles}</p>
        </div>
        <br />
        <div>
          <h3>Donations</h3>
          <p style={{ paddingBottom: "1em" }}>{donations}</p>
          <HrefButton linkRef={donate} styleName={"align-end"}>
            Just Giving
          </HrefButton>
        </div>
      </div>
    </div>
  )
}

export default GetInvolved
