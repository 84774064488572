import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Intro from "../components/Sections/intro"
import About from "../components/Sections/about"
import Hero from "../components/Sections/hero"
import FindUs from "../components/Sections/find-us"
import GetInvolved from "../components/Sections/get-involved"
import Pray from "../components/Sections/pray"
import MealForm from "../components/UI/meal-form"

const IndexPage = ({ data }) => {
  const { title, details, about, findus, introduction, involved, prayer } =
    data.allFile.edges[0].node.childMarkdownRemark.frontmatter

  const currentDate = new Date()
  const actieVenues = findus.venues.filter(venue => venue.active)
  const inDateVenues = actieVenues.filter(
    venue => new Date(venue.dates.end_date).getTime() > currentDate.getTime()
  )

  return (
    <Layout>
      <Seo title="Home" />
      <Hero title={title} venues={inDateVenues} />
      <MealForm venues={inDateVenues} />
      <div className="container">
        <Intro intro={introduction} />
        <FindUs title={findus.title} venues={inDateVenues} />
        <About about={about} />
        <GetInvolved
          involved={involved}
          donate={details.donate}
          venues={inDateVenues}
        />
        <Pray prayer={prayer} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allFile(filter: { name: { eq: "home" } }) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              details {
                email
                tel
                donate
                twitter
              }
              title
              about {
                body
                logo
                subtitle
                title
                image {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      formats: [PNG, WEBP, AVIF]
                    )
                  }
                }
              }
              findus {
                title
                venues {
                  active
                  address
                  dates {
                    start_date
                    end_date
                  }
                  image {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: BLURRED
                        formats: [PNG, WEBP, AVIF]
                      )
                    }
                  }
                  map
                  name
                  times
                }
              }
              introduction {
                body
                subtitle
                title
                image {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      formats: [PNG, WEBP, AVIF]
                    )
                  }
                }
              }
              involved {
                body
                donations
                roles
                subtitle
                title
                imagegrid {
                  roles
                  image1 {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: BLURRED
                        formats: [PNG, WEBP, AVIF]
                      )
                    }
                  }
                  image2 {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: BLURRED
                        formats: [PNG, WEBP, AVIF]
                      )
                    }
                  }
                  image3 {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: BLURRED
                        formats: [PNG, WEBP, AVIF]
                      )
                    }
                  }
                }
              }
              prayer {
                body
                title
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
