import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import Logo from "../../../static/assets/icons/navlogo.svg"

const About = ({ about }) => {
  const Sections = about.map((section, i) => {
    const { body, logo, subtitle, title, image } = section
    let mobile = ""
    if (i === 2) mobile = "mobile-reverse-grid"
    if (i % 2 === 0) {
      return (
        <div className={`${mobile} media-grid reverse-grid`} key={title}>
          <div>
            <GatsbyImage
              className="static-image"
              image={image.childImageSharp.gatsbyImageData}
              alt={title}
            />
          </div>
          <div>
            <h3>{title}</h3>
            <h5>{subtitle}</h5>
            <p>{body}</p>
            {logo ? <Logo /> : null}
          </div>
        </div>
      )
    } else {
      return (
        <div className="media-grid reverse-grid" key={title}>
          <div>
            <h3>{title}</h3>
            <h5>{subtitle}</h5>
            <p>{body}</p>
          </div>
          <div>
            <GatsbyImage
              className="static-image"
              image={image.childImageSharp.gatsbyImageData}
              alt={title}
            />
          </div>
        </div>
      )
    }
  })
  return <div style={{ marginTop: "70px" }}>{Sections}</div>
}

export default About
