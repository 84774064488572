import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const Intro = ({ intro }) => {
  const { body, subtitle, title, image } = intro
  return (
    <div className="media-grid">
      <div className="text-right">
        <h1>{title}</h1>
        <h4 style={{ margin: "1em 0" }}>{subtitle}</h4>
        <p>{body}</p>
      </div>
      <div>
        <GatsbyImage
          image={image.childImageSharp.gatsbyImageData}
          alt="food #1"
        />
      </div>
    </div>
  )
}

export default Intro
