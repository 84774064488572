import React from "react"
import styled from "styled-components"

const Vector = styled.div`
  margin: auto;
  color: white;
  width: fit-content;

  .spinner {
    animation: rotate 2s linear infinite;
    z-index: 2;
    margin: auto;
    width: 50px;
    height: 50px;

    & .path {
      stroke: var(--cafe-color-yellow);
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`

const Spinner = () => {
  return (
    <Vector>
      <svg className="spinner" viewBox="0 0 50 50">
        <circle
          className="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="5"
        ></circle>
      </svg>
    </Vector>
  )
}

export default Spinner
