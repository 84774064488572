import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Hero = ({ title, venues }) => {
  const Venues = () => {
    return venues.map(venue => {
      const start = new Date(venue.dates.start_date)
      const end = new Date(venue.dates.end_date)

      return (
        <div
          style={{
            borderBottom: "solid 1px white",
          }}
        >
          <h4>{venue.name}</h4>
          <br />
          <p style={{ lineHeight: "0.75" }}>{venue.address}</p>
          <br />
          <p>
            Open{" "}
            {venue.times.map(time => (
              <>
                <>{time}</>
                <br />
              </>
            ))}
            <br />
            <small>
              from {start.toDateString()} to {end.toDateString()}
            </small>
          </p>
          <br />
        </div>
      )
    })
  }

  return (
    <div className="hero">
      <div className="hero-content">
        <div className="hero-content-block">
          <h3>{title}</h3>
          <Venues />
        </div>
      </div>
      <div className="hero-image">
        <StaticImage
          src="../../../static/assets/woman-hero.jpg"
          style={{ position: "absolute", inset: "0" }}
          placeholder="blurred"
          backgroundColor="black"
          alt="Our chefs"
        />
        <div className="hero-overlay" />
      </div>
    </div>
  )
}

export default Hero
