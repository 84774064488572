import React, { useRef, useState } from "react"
import Spinner from "../../../static/assets/icons/spinner"
import { SubmitButton } from "./Buttons"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const MealForm = ({ venues }) => {
  const [details, setDetails] = useState({
    name: "",
    contact: "none provided",
    time: "",
    location: "",
  })
  const [thanks, setThanks] = useState(false)
  const [loading, isLoading] = useState(false)
  const venue = useRef()

  //Painful use of conditionals, in the case that venue changes but time stays as the default/1st option...

  const handleChange = e => {
    if (e.target.name === "location") {
      venue.current = venues.filter(venue => venue.name === e.target.value)[0]
      setDetails({
        ...details,
        [e.target.name]: e.target.value,
        time: venue.current.times[0],
      })
    } else
      setDetails({
        ...details,
        time: venues[0].times[0],
        [e.target.name]: e.target.value,
      })
  }

  const handleSubmit = e => {
    e.preventDefault()
    isLoading(true)
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      body: encode({ "form-name": "meal", ...details }),
    })
      .then(() => {
        console.log(details)
        setThanks(!thanks)
        isLoading(false)
      })
      .catch(error => alert(error))
  }

  return (
    <div className="form-section" id="booking">
      <h4 style={{ color: "white", margin: "1em 0" }}>
        To book a meal, please enter your details below:
      </h4>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {thanks ? (
            <div
              style={{
                margin: "auto",
                color: "white",
                textAlign: "center",
                border: "double 1px white",
                width: "fit-content",
                padding: "12px",
              }}
            >
              <h5>Thank you. Your name will be added to the list.</h5>
            </div>
          ) : (
            <>
              <form
                data-netlify="true"
                name="meal"
                onSubmit={handleSubmit}
                className="meal-form flex"
              >
                <input type="hidden" name="form-name" value="meal" />
                <div className="input-box">
                  <input
                    type="text"
                    name="name"
                    style={{ width: "300px" }}
                    placeholder="Name:"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="input-box">
                  <input
                    type="text"
                    name="contact"
                    style={{ width: "300px" }}
                    placeholder="Contact: (optional Email/Tel)"
                    onChange={handleChange}
                    minLength={0}
                  />
                </div>
                <div className="input-box">
                  <select
                    name="location"
                    placeholder="Preferred location"
                    onChange={handleChange}
                    className="fullwidth"
                    required
                  >
                    {venues.map(venue => (
                      <option value={venue.name} key={venue.name}>
                        {venue.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-box">
                  <select
                    name="time"
                    placeholder="Preferred time"
                    onChange={handleChange}
                    className="fullwidth"
                    required
                  >
                    {(venue.current || venues[0]).times.map(time => (
                      <option value={time} key={time}>
                        {time}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-box end-grid">
                  <SubmitButton>Submit</SubmitButton>
                </div>
              </form>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default MealForm
