import React, { useState } from "react"
import { SubmitButton } from "./Buttons"
import Spinner from "../../../static/assets/icons/spinner"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const VolunteerForm = ({ venues }) => {
  const [details, setDetails] = useState({
    name: "",
    email: "",
    phone: "",
    role: "",
    location: "",
  })

  const [thanks, setThanks] = useState(false)
  const [loading, isLoading] = useState(false)

  const [errors, setErrors] = useState({
    errorName: "",
    errorEmail: "",
  })

  const handleChange = e => {
    setDetails({ ...details, [e.target.name]: e.target.value })
    setErrors({ errorName: "", errorEmail: "" })
  }

  function handleValidation() {
    let formIsValid = true
    let name = details.name
    let email = details.email
    let lastAtPos = details.email.lastIndexOf("@")
    let lastDotPos = details.email.lastIndexOf(".")

    if (!name) {
      formIsValid = false
      setErrors({ errorName: "This cannot be empty" })
    }

    if (!email) {
      formIsValid = false
      setErrors({ errorEmail: "This cannot be empty" })
    } else if (
      !(
        lastAtPos < lastDotPos &&
        lastAtPos > 0 &&
        details.email.indexOf("@@") === -1 &&
        lastDotPos > 2 &&
        details.email.length - lastDotPos > 2
      )
    ) {
      formIsValid = false
      setErrors({ errorEmail: "Email is not valid" })
    }

    return formIsValid
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (handleValidation()) {
      isLoading(true)
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "multipart/form-data" },
        body: encode({ "form-name": "volunteer", ...details }),
      })
        .then(() => {
          setThanks(!thanks)
          isLoading(false)
        })
        .catch(error => alert(error))
    }
  }

  return (
    <div>
      {loading ? (
        <div style={{ margin: "auto" }}>
          <h5>Uploading...</h5>
          <Spinner />
        </div>
      ) : (
        <>
          {thanks ? (
            <div style={{ margin: "auto", textAlign: "center" }}>
              <h5>
                Thank you for offering to volunteer. Someone will be in touch.
              </h5>
            </div>
          ) : (
            <form
              data-netlify="true"
              data-netlify-recaptcha="true"
              name="volunteer"
              onSubmit={handleSubmit}
              className="address-box"
            >
              <input type="hidden" name="form-name" value="volunteer" />
              <div className="input-box">
                <input
                  type="text"
                  name="name"
                  placeholder="Name:"
                  onChange={handleChange}
                  className="fullwidth"
                  required
                />
                <label>{errors.errorName}</label>
              </div>
              <div className="input-box">
                <input
                  type="text"
                  name="email"
                  placeholder="Email:"
                  className="fullwidth"
                  onChange={handleChange}
                  required
                />
                <label>{errors.errorEmail}</label>
              </div>
              <div className="input-box">
                <input
                  type="tel"
                  name="phone"
                  placeholder="Tel:"
                  className="fullwidth"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="input-box">
                <select
                  name="role"
                  placeholder="Preferred role"
                  onChange={handleChange}
                  className="fullwidth"
                  required
                >
                  <option value="Welcoming guests">welcome</option>
                  <option value="cooking or preparing food">chef</option>
                  <option value="helping to set tables and prepare the room">
                    setting
                  </option>
                  <option value="conversing with guests and providing help">
                    listen
                  </option>
                </select>
              </div>
              <div className="input-box">
                <select
                  name="location"
                  placeholder="Preferred location"
                  onChange={handleChange}
                  className="fullwidth"
                  required
                >
                  {venues.map(venue => (
                    <option value={venue.name} key={venue.name}>
                      {venue.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="input-box end-grid">
                <SubmitButton
                  styleName={"align-end "}
                  buttonColor={"var(--cafe-color-orange)"}
                >
                  Submit
                </SubmitButton>
              </div>
            </form>
          )}
        </>
      )}
    </div>
  )
}

export default VolunteerForm
