import React from "react"
import Logo from "../../../static/assets/icons/mobile-logo.svg"

const Pray = ({ prayer }) => {
  const { body, title } = prayer
  return (
    <div style={{ background: "var(--cafe-color-orange" }}>
      <div className="media-grid assymetric-grid">
        <div>
          <h3>{title}</h3>
          <p>
            <br />
            {body}
          </p>
        </div>
        <div style={{ transform: "translate(0px, -30px)" }}>
          <Logo />
        </div>
      </div>
    </div>
  )
}

export default Pray
