import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { HrefButton } from "../UI/Buttons"

const FindUs = ({ venues, title }) => {
  const Venues = () => {
    return venues.map(venue => {
      const start = new Date(venue.dates.start_date)
      const end = new Date(venue.dates.end_date)

      return (
        <div
          className="flex"
          style={{ alignItems: "unset", gap: "1rem" }}
          key={venue.name}
        >
          <div style={{ alignSelf: "flex-start", maxWidth: "600px" }}>
            <GatsbyImage
              image={venue.image.childImageSharp.gatsbyImageData}
              alt={venue.name}
            />
          </div>
          <div>
            <h4>{venue.name}</h4>
            <br />
            <p style={{ lineHeight: "0.75" }}>{venue.address}</p>
            <br />
            <p>
              Open{" "}
              {venue.times.map(time => (
                <>
                  <>{time}</>
                  <br />
                </>
              ))}
              <br />
              <small>
                from {start.toDateString()} to {end.toDateString()}
              </small>
            </p>
            <br />
            <HrefButton linkRef={venues.map} styleName={"align-end"}>
              Map
            </HrefButton>
          </div>
        </div>
      )
    })
  }

  return (
    <div id="find-us" style={{ padding: "4rem" }}>
      <div className="text-right full-span">
        <h3>{title}</h3>
      </div>
      <div className="venues">
        <Venues />
      </div>
    </div>
  )
}

export default FindUs
